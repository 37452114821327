import React from "react";
/** @jsx jsx */
import { Box, jsx, useThemeUI } from "theme-ui";
import {
  Container,
  Flex,
  FlexGrid,
  Heading,
  AspectRatio,
  Text,
} from "../../components/core";
import Page from "../../components/Page";
import { Parallax } from "../../components/animate";
import { alpha } from "@theme-ui/color";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import { Card } from "../../components/Card";
import { Content } from "../../components/Card/NousAider";
import { getImage } from "gatsby-plugin-image";
import { Vague } from "../../components/svg";

const NousAider = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const { theme } = useThemeUI();

  return (
    <Page sx={{ backgroundColor: "background3" }}>
      <SEO title="Nous aider" description={frontmatter.intro.text} />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête nos actions"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
              sx={{ textAlign: "center" }}
            >
              <Heading as="h1" color="white">
                Nous aider
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Container sx={{ paddingTop: [8, null, 10] }}>
          <Box>
            <Heading as="h2" color="secondary" sx={{ marginBottom: 3 }}>
              {frontmatter.intro.title}
            </Heading>
            <Text>{frontmatter.intro.text}</Text>
            <Vague
              color="primary"
              sx={{
                marginTop: 6,
                marginBottom: 11,
              }}
            />
          </Box>
        </Container>
        <Container sx={{ paddingBottom: 10 }} variant="containerLg">
          <FlexGrid
            positions={["stretch", "stretch"]}
            columns={[12, 4, null]}
            gutter={5}
          >
            <Box>
              <Card to="/don-libre">
                <Content>
                  <Content.Title>Don libre</Content.Title>
                  <Content.Text>{frontmatter.donLibre.text}</Content.Text>
                  <Content.Button>Faire un don</Content.Button>
                </Content>
              </Card>
            </Box>
            <Box>
              <Card to="/adhesion">
                <Content>
                  <Content.Title>Adhésion</Content.Title>
                  <Content.Text>{frontmatter.adhesion.text}</Content.Text>
                  <Content.Button>Adhérer à CMC</Content.Button>
                </Content>
              </Card>
            </Box>
            <Box>
              <Card to="/contact">
                <Content>
                  <Content.Title>Autre</Content.Title>
                  <Content.Text>{frontmatter.partage.text}</Content.Text>
                  <Content.Button>Nous contacter</Content.Button>
                </Content>
              </Card>
            </Box>
          </FlexGrid>
        </Container>
      </Box>
    </Page>
  );
};

export default NousAider;

export const query = graphql`
  query NousAiderQueryData {
    markdownRemark(fields: { slug: { eq: "/nous-aider/" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        intro {
          title
          text
        }
        donLibre {
          text
        }
        adhesion {
          text
        }
        partage {
          text
        }
      }
    }
  }
`;
