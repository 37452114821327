import { Card } from "./index";
import { Button, Heading, Text } from "../core";
import { ReactMarkdown } from "../ReactMarkdown";
import React from "react";

const Content = ({ children }) => (
  <Card.Content
    sx={{
      py: 6,
      px: 4,
    }}
    positions={["center", "flex-start"]}
  >
    {children}
  </Card.Content>
);

const TitleStyle = ({ children }) => (
  <Heading as="h2" color="primary" sx={{ marginBottom: 3 }}>
    {children}
  </Heading>
);

const TextStyle = ({ children }) => (
  <Text sx={{ flexGrow: 1 }} as="div">
    <ReactMarkdown>{children}</ReactMarkdown>
  </Text>
);

const ButtonStyle = ({ children }) => (
  <Button
    sx={{
      width: "100%",
      maxWidth: "285px",
      textTransform: "uppercase",
      marginTop: 5,
    }}
  >
    {children}
  </Button>
);

Content.Title = TitleStyle;
Content.Text = TextStyle;
Content.Button = ButtonStyle;

export { Content };
